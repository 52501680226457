//
// helper.scss
//

.section {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
}

.vertical-content {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: row;
  -webkit-flex-direction: row;
}

.bg-overlay {
  background-color: rgba(4, 3, 3, 0.521);
  opacity: 0.9;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.btn {
  font-size: 0.9rem;
  padding: 0.7rem 1.4rem;
  transition: all 0.4s;
  border-radius: 7px;
  &:hover {
    outline: none;
    text-decoration: none;
  }
}

.whatsapp {
  margin-right: 0.5rem;
  background-color: #25d366;
  font-size: 0.9rem;
  padding: 0.7rem 1.4rem;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  border-radius: 7px;
  &:hover {
    background-color: #1ea952;
  }
}
.viber {
  margin-right: 0.5rem;
  background-color: #59267c;
  font-size: 0.9rem;
  padding: 0.7rem 1.4rem;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  border-radius: 7px;
  &:hover {
    background-color: #7a5196;
  }
}
.phone-btn {
  margin-right: 0.5rem;
  background-color: #fa5252;
  font-size: 0.9rem;
  padding: 0.7rem 1.4rem;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  border-radius: 7px;
  &:hover {
    background-color: #e03131;
  }
}

.form-contact {
  .d-flex {
    display: block !important;
    justify-content: start;
    margin: 0;
  }
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.btn-rounded {
  border-radius: 30px;
}

// font-size

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-15 {
  font-size: 15px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-30 {
  font-size: 30px;
}

// line-height

.line-height_1_4 {
  line-height: 1.4;
}

.line-height_1_6 {
  line-height: 1.6;
}

.line-height_1_8 {
  line-height: 1.8;
}

// box-shadow

.box-shadow {
  box-shadow: 0px 10px 22px rgba(0, 0, 0, 0.05);
}

.box-shadow-lg {
  box-shadow: 0px 10px 28px rgba(0, 0, 0, 0.1);
}

// heading

.header-title {
  .title-desc {
    max-width: 650px;
    margin: 0 auto;
  }
  .title-border {
    width: 80px;
    height: 1px;
    background: $primary;
    background: linear-gradient(to right, $primary, $primary, transparent);
    margin: 0 auto;
  }
}

.lighlight-border {
  width: 60px;
  height: 1px;
  background: $primary;
  background: linear-gradient(to right, $primary, $primary, transparent);
  margin: 0 auto;
}

// read More

.read-more {
  color: $dark;
  font-weight: 500;
  padding: 2px 0px;

  &:hover {
    color: $primary;
    padding: 2px 0px;
    transition: all 0.3s;
  }
  i {
    font-size: 16px;
    position: relative;
    top: 2px;
  }
}
