//
// responsive.scss
//

@media (min-width: 200px) and (max-width: 992px) {
  .navbar-custom.nav-sticky {
    margin-top: 0px;
    padding: 15px 0px !important;
    box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
    color: $white !important;

    .navbar-nav {
      margin-top: 0px;
      li {
        &.active {
          border-color: transparent;
        }
        a {
          transition: all 0.4s;
          color: $dark !important;
          margin: 0px;
        }
      }
    }
    > .container {
      width: 90%;
    }
  }

  .navbar-custom {
    .navbar-nav {
      margin-top: 0px;
      li {
        &.active {
          border-color: transparent;
        }
        a {
          transition: all 0.4s;
          color: $white !important;
          margin: 0px;
        }
      }
    }
  }
  .navbar-custom .navbar-nav li.active a,
  .navbar-custom .navbar-nav li a:hover,
  .navbar-custom .navbar-nav li a:active {
    color: $primary !important;
  }

  .navbar-toggler {
    border: none;
    color: $white;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-toggler-light {
    font-size: 24px;
    margin-top: 5px;
    margin-bottom: 0px;
    color: $white;
  }

  .navbar-toggler-dark {
    font-size: 24px;
    margin-top: 5px;
    margin-bottom: 0px;
    color: $dark;
  }

  .home-content {
    .title {
      font-size: 44px;
    }
  }

  .logo {
    .logo-light {
      display: none;
    }
    .logo-dark {
      display: inline-block;
    }
  }

  .progress-border {
    &:before {
      border: none;
    }
  }
}

@media (max-width: 425px) {
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    // display: none;
    background-color: "";
  }

  .carousel-indicators {
    li {
      width: 50px;
      height: 50px;
    }
  }
}

.navbar-collapse.show {
  display: flex;
}
